import { useMemo, useCallback } from "react";
import type { Media, SvgItem } from "../../../types";
import { EventAction } from "../../../config/events";
import { slugify } from "../../../util/text";
import { dispatchGoogleEvent } from "../../../util/google";
import useUTMParams from "../../../hooks/use-utm-params";
import { buttonVariant, type ButtonVariantProps } from "./button.variants";

export type UseButtonProps = ButtonVariantProps & {
  id?: string;
  className?: string;
  sectionName?: string;
  href?: string;
  type?: "button" | "submit";
  title?: string | null;
  children?: React.ReactNode;
  subText?: string | null;
  image?: Media | null;
  loadingLabel?: string;
  eventAction?: EventAction | string;
  startIcon?: SvgItem;
  endIcon?: SvgItem;
  isLoading?: boolean;
  disabled?: boolean;
  hide?: boolean;
  newTab?: boolean;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  tabIndex?: number;
  "aria-expanded"?: boolean;
  "aria-haspopup"?: boolean;
};

const regex = /^(https:\/\/(developer\.)?moises\.ai(\/[^\s]*)?)|(^\/[^\s]*)$/;

export const useButton = (props: UseButtonProps) => {
  const {
    className = "",
    sectionName = "",
    href,
    children,
    title,
    subText = null,
    image = null,
    startIcon = null,
    endIcon = null,
    appearance = "link",
    size = "md",
    color = "white",
    weight = "semibold",
    disabled = false,
    rounded = false,
    fluid,
    isLoading = false,
    wrap = false,
    loadingLabel,
    onClick,
    hide = false,
    newTab = false,
    eventAction: eventActionProp = EventAction.WebsiteClick,
    ...componentProps
  } = props;

  const isImage = !!image;
  const isIconOnly = !children && (!!startIcon || !!endIcon);
  const isDisabled = isLoading || disabled;
  const isWebsiteLink = href && regex.test(href);

  const utm = useUTMParams();

  const styles = useMemo(
    () =>
      buttonVariant({
        appearance,
        size,
        color,
        weight,
        rounded,
        fluid,
        isIconOnly,
        isImage,
        wrap,
      }),
    [appearance, size, color, weight, rounded, fluid, isIconOnly, isImage, wrap]
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      if (isDisabled) return;

      const { eventAction, eventLabel } = e.currentTarget.dataset;

      if (eventAction && eventLabel) {
        dispatchGoogleEvent(eventAction, eventLabel);
      }

      if (onClick) {
        onClick(e);
      }
    },
    [isDisabled, onClick]
  );

  const slugifiedSectionName = slugify(sectionName);

  const getHrefWithUTM = () => {
    if (!href) {
      return;
    }

    return utm.appendUTMParams(href, slugifiedSectionName);
  };

  return {
    image,
    children,
    className,
    title,
    subText,
    startIcon,
    endIcon,
    loadingLabel,
    href: getHrefWithUTM(),
    newTab,
    styles,
    hide,
    isLoading,
    isDisabled,
    isWebsiteLink,
    isIconOnly,
    componentProps: {
      ...componentProps,
      onClick: handleClick,
      "data-event-action": eventActionProp,
      "data-event-label": slugifiedSectionName,
    },
  };
};
