import type { Config } from "tailwindcss";
import defaultTheme from "tailwindcss/defaultTheme";

const preset = {
  content: [],
  theme: {
    spacing: {
      px: "1px",
      0: "0px",
      0.5: "0.125rem",
      1: "0.25rem",
      1.5: "0.375rem",
      2: "0.5rem",
      2.5: "0.625rem",
      3: "0.75rem",
      3.5: "0.875rem",
      4: "1rem",
      5: "1.25rem",
      6: "1.5rem",
      7: "1.75rem",
      8: "2rem",
      9: "2.25rem",
      10: "2.5rem",
      11: "2.75rem",
      12: "3rem",
      13: "3.25rem",
      14: "3.5rem",
      16: "4rem",
      20: "5rem",
      24: "6rem",
      28: "7rem",
      32: "8rem",
      36: "9rem",
      40: "10rem",
      44: "11rem",
      48: "12rem",
      52: "13rem",
      56: "14rem",
      60: "15rem",
      64: "16rem",
      72: "18rem",
      80: "20rem",
      96: "24rem",
    },
    fontFamily: {
      sans: ["var(--font-articulat)", ...defaultTheme.fontFamily.sans],
    },
    fontSize: {
      "display-10": "0.625rem",
      "display-12": "0.75rem",
      "display-14": "0.875rem",
      "display-16": "1rem",
      "display-18": "1.125rem",
      "display-20": "1.25rem",
      "display-24": "1.5rem",
      "display-28": "1.75rem",
      "display-32": "2rem",
      "display-40": "2.5rem",
      "display-48": "3rem",
      "display-52": "3.25rem",
      "display-56": "3.5rem",
      "display-60": "3.75rem",
      "display-64": "4rem",
      "display-72": "4.5rem",
      "display-80": "5rem",
      "display-92": "5.75rem",
      "display-96": "6rem",
    },
    letterSpacing: {
      tighter: "-0.05em",
      tight: "-0.025em",
      normal: "0em",
      wide: "0.05em",
      wider: "0.1em",
      widest: "0.2em",
    },
    lineHeight: {
      none: "1",
      tight: "1.1",
      snug: "1.2",
      normal: "1.3",
      relaxed: "1.4",
      loose: "1.5",
    },
    borderRadius: {
      none: "0px",
      "2xs": "0.063rem",
      xs: "0.125rem",
      sm: "0.25rem",
      md: "0.375rem",
      lg: "0.5rem",
      xl: "0.75rem",
      "2xl": "1rem",
      "3xl": "1.25rem",
      "4xl": "1.5rem",
      full: "100vh",
    },
    boxShadow: {
      none: "none",
      xs: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      sm: " 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
      md: "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",
      lg: "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
      xl: "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
      "2xl": "0px 24px 48px -12px rgba(16, 24, 40, 0.18)",
      "3xl": "0px 32px 64px -12px rgba(16, 24, 40, 0.14)",
    },
    screens: {
      xxs: "320px",
      xs: "480px",
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
      "2xl": "1680px",
      "3xl": "1920px",
      "4xl": "2200px",
      "5xl": "2560px",
      "6xl": "3840px",
      "7xl": "5120px",
      desktop: { min: "1025px" },
      mobile: { max: "1024px" },
    },
    animation: {
      none: "none",
      spin: "spin 1s linear infinite",
      ping: "ping 1s cubic-bezier(0, 0, 0.2, 1) infinite",
      pulse: "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
      bounce: "bounce 1s infinite",
      "fade-in": "fade-in 0.5s linear forwards",
      "ticker-up": "ticker-up var(--ticker-duration) linear infinite",
      "ticker-down": "ticker-down var(--ticker-duration) linear infinite",
      "ticker-left": "ticker-left var(--ticker-duration) linear infinite",
      "ticker-right": "ticker-right var(--ticker-duration) linear infinite",
      "pulse-circle": "pulse-circle 8s ease-out infinite",
    },
    keyframes: {
      shimmer: {
        "100%": {
          transform: "translateX(100%)",
        },
      },
      spin: {
        to: {
          transform: "rotate(360deg)",
        },
      },
      ping: {
        "75%, 100%": {
          transform: "scale(2)",
          opacity: "0",
        },
      },
      pulse: {
        "50%": {
          opacity: ".5",
        },
      },
      bounce: {
        "0%, 100%": {
          transform: "translateY(-25%)",
          animationTimingFunction: "cubic-bezier(0.8,0,1,1)",
        },
        "50%": {
          transform: "none",
          animationTimingFunction: "cubic-bezier(0,0,0.2,1)",
        },
      },
      "fade-in": {
        from: {
          opacity: "0",
        },
        to: {
          opacity: "1",
        },
      },
      "ticker-left": {
        "100%": {
          transform: "translateX(var(--ticker-x))",
        },
      },
      "ticker-right": {
        "100%": {
          transform: "translateX(var(--ticker-x))",
        },
      },
      "ticker-up": {
        "100%": {
          transform: "translateY(var(--ticker-y))",
        },
      },
      "ticker-down": {
        "100%": {
          transform: "translateY(var(--ticker-y))",
        },
      },
      "pulse-circle": {
        "0%": {
          boxShadow: "0 0 0 4px rgba(133, 133, 133, 0.20)",
          transform: "scale(0)",
          opacity: "0",
        },
        "50%": {
          boxShadow: "0 0 0 3px rgba(133, 133, 133, 0.15)",
          opacity: ".75",
        },
        "85%": {
          boxShadow: "0 0 0 2px rgba(133, 133, 133, 0.05)",
        },
        "100%": {
          boxShadow: "0 0 0 1px rgba(133, 133, 133, 0)",
          transform: "scale(1)",
        },
      },
    },
    backgroundImage: {
      none: "none",
      "gradient-to-t": "linear-gradient(to top, var(--tw-gradient-stops))",
      "gradient-to-tr":
        "linear-gradient(to top right, var(--tw-gradient-stops))",
      "gradient-to-r": "linear-gradient(to right, var(--tw-gradient-stops))",
      "gradient-to-br":
        "linear-gradient(to bottom right, var(--tw-gradient-stops))",
      "gradient-to-b": "linear-gradient(to bottom, var(--tw-gradient-stops))",
      "gradient-to-bl":
        "linear-gradient(to bottom left, var(--tw-gradient-stops))",
      "gradient-to-l": "linear-gradient(to left, var(--tw-gradient-stops))",
      "gradient-to-tl":
        "linear-gradient(to top left, var(--tw-gradient-stops))",
      "gradient-radial-1":
        "radial-gradient(50% 50% at 50% 50%, #1040FF 0%, rgba(1, 1, 1, 0.00) 100%)",
      "header-dark":
        "linear-gradient(180deg, #666666 -270%, rgba(0, 0, 0, 0.6) 150%)",
      "header-light":
        "linear-gradient(180deg, #f5f5f5 -270%, rgb(255, 255, 255) 150%)",
      "select-arrow":
        "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC4yOTI4OTMgMC4yOTI4OTNDMC42ODM0MTYgLTAuMDk3NjMxIDEuMzE2NTggLTAuMDk3NjMxIDEuNzA3MSAwLjI5Mjg5M0w0Ljk5OTk5IDMuNTg1NzlMOC4yOTI4OCAwLjI5Mjg5M0M4LjY4MzQgLTAuMDk3NjMxMSA5LjMxNjU3IC0wLjA5NzYzMTEgOS43MDcwOSAwLjI5Mjg5M0MxMC4wOTc2IDAuNjgzNDE3IDEwLjA5NzYgMS4zMTY1OCA5LjcwNzA5IDEuNzA3MTFMNS43MDcxIDUuNzA3MTFDNS4zMTY1NyA2LjA5NzYzIDQuNjgzNDEgNi4wOTc2MyA0LjI5Mjg5IDUuNzA3MTFMMC4yOTI4OTMgMS43MDcxMUMtMC4wOTc2MzA5IDEuMzE2NTggLTAuMDk3NjMwOSAwLjY4MzQxNyAwLjI5Mjg5MyAwLjI5Mjg5M1oiIGZpbGw9IiNCNUI1QjUiLz4KPC9zdmc+Cg==)",
    },
  },
} satisfies Config;

export default preset;
